import React from 'react'

import '../../styles/about.css'

import aboutImg from '../../images/about-us.jpg'

const chooseData = [
    {
        icon: 'ri-wifi-line',
        title: 'Fast Working Process',
        // desc: 'Ensuring seamless project execution.'
    },
    {
        icon: 'ri-team-line',
        title: 'Dedicated Team',
        // desc: 'Focused and committed to your needs.'
    },
    {
        icon: 'ri-customer-service-2-line',
        title: '24/7 Support',
        // desc: 'Always available to assist you.'
    }    
]

const About = () => {
    return <section id="about">
        <div className="container">
            <div className="about__wrapper">
                <div className="about__content">
                    <h6 className="subtitle">Why Choose Us</h6>
                    <h2>Experienced in helping clients with </h2>
                    <h2 className="highlights"> financial challenges</h2>
                    <p className="description about__content-desc">
                        Our team of experts is dedicated to providing professional services with a personal touch. We take the time to understand your unique needs and goals, and then develop a customized solution that addresses them. We believe in building long-term relationships with our clients, and we're committed to delivering exceptional service and results that exceed your expectations.
                    </p>

                    <div className="choose__item-wrapper">
                        {
                                chooseData.map((item, index) => (
                                    <div className="choose__us-item" key={index}>
                                        <span className="choose__us-icon">
                                            <i class={item.icon}></i>
                                        </span>
                                        <div>
                                            <h4 className="choose__us-title">
                                            {item.title}
                                            </h4>
                                            {/* <p className="description">
                                                {item.desc}
                                            </p> */}
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
                <div className="about__img">
                    <img src={aboutImg} alt="" />
                </div>
            </div>
        </div>
    </section>
}

export default About